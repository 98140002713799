import { Component } from 'react';

import BodyText from '../atoms/BodyText';
import Header, { HeaderEnum } from '../atoms/Header';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import CallToAction from './CallToAction';
import InlinedRichText from './InlinedRichText';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import {
  HeaderTextGroupTypeEnum,
  TypeHeaderTextGroupTypes,
  TypeSEOWeightTypes,
} from '../../../constants/cms-constants';
import getSEOWeight from '../../../utils/SEOWeight';

// Unsure if Breakpoints should be changed to Breakpoints of type down
const styles = {
  '& .left-text': {
    paddingRight: '1em',
    [Breakpoints.Mobile.mq]: {
      marginBottom: '26px',
    },
    [Breakpoints.Tablet.mq]: {
      marginBottom: '32px',
    },
  },
  '& .cta': {
    [Breakpoints.Mobile.mq]: {
      marginTop: '16px',
    },
    [Breakpoints.Tablet.mq]: {
      marginTop: '23px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginTop: '18px',
    },
  },
};

type HeaderTextGroupProps = {
  type?: TypeHeaderTextGroupTypes;
  enableRichText?: boolean;
  bodyText?: InternalPropTypes.RichText;
  freezeInBreakpoint?: InternalPropTypes.Breakpoint;
  headerText?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
  seoWeight?: TypeSEOWeightTypes;
};

class HeaderTextGroup extends Component<HeaderTextGroupProps> {
  static types = HeaderTextGroupTypeEnum;

  static defaultProps = {
    type: this.types.WIDE,
    enableRichText: false,
    headerText: null,
    bodyText: null,
    freezeInBreakpoint: Breakpoints.Mobile,
    link: null,
    seoWeight: 'p',
  };

  render() {
    const {
      type,
      freezeInBreakpoint,
      headerText,
      bodyText,
      link,
      enableRichText,
      seoWeight,
    } = this.props;

    let typeOfText: HeaderEnum;
    let leftCellCount = 5;
    let rightCellCount = 5;

    switch (type) {
      case HeaderTextGroup.types.NARROW:
        typeOfText = Header.types.NAMEBASE;
        break;
      case HeaderTextGroup.types.LARGE_NARROW:
        typeOfText = Header.types.NAMEBASE;
        break;
      case HeaderTextGroup.types.LARGE_WIDE:
        typeOfText = Header.types.NAMEBASE;
        leftCellCount = 3;
        rightCellCount = 9;
        break;
      default:
        typeOfText = Header.types.NAMEBASE;
        leftCellCount = 3;
        rightCellCount = 9;
    }

    const breakpoint =
      freezeInBreakpoint === Breakpoints.Mobile
        ? Breakpoints.Mobile
        : Breakpoints.Tablet;

    return (
      <div css={styles}>
        <div className={`header-text-group-molecule-${type}`}>
          <Grid2CellUnevenSplit
            leftCellCount={leftCellCount}
            rightCellCount={rightCellCount}
            snapAtBreakpoint={breakpoint}
            onRenderCell1={() => (
              <div className="left-text">
                {headerText && (
                  <Header
                    seoWeight={getSEOWeight(seoWeight, 'h2')}
                    type={typeOfText}
                  >
                    {headerText}
                  </Header>
                )}
              </div>
            )}
            onRenderCell2={() => (
              <div className="right-text">
                {bodyText &&
                  (enableRichText ? (
                    <InlinedRichText
                      richText={bodyText}
                      className="rich-text"
                      openInNewWindowOrTab={link?.openInNewWindowOrTab}
                    />
                  ) : (
                    <BodyText
                      className="body-text"
                      type={BodyText.types.STATIC16}
                    >
                      {bodyText}
                    </BodyText>
                  ))}

                {link && <CallToAction link={link} />}
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

export default HeaderTextGroup;
