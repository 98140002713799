import React, { Component } from 'react';
import { css } from '@emotion/react';

import Image from '../atoms/Image';
import Caption from '../atoms/Caption';
import Header from '../atoms/Header';
import BodyText from '../atoms/BodyText';
import CallToAction from './CallToAction';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import {
  filterPhone,
  filterEmail,
} from '../../../utils/filterContactInformation';
import {
  generateEmailProps,
  generatePhoneProps,
} from '../../../utils/generateContactInfoProps';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import {
  ProfileEnum,
  TypeProfileTypes,
} from '../../../constants/cms-constants';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  '& .image': {
    marginTop: '20px',
    marginBottom: '20px',
    objectFit: 'scale-down',
    [Breakpoints.TabletUp.mq]: {
      height: 'auto',
      width: '100%',
    },
    [Breakpoints.Mobile.mq]: {
      maxHeight: '400px',
      width: 'auto',
    },
  },
  '& .header-text p': {
    marginBottom: 0,
  },
  '& .title-profile': {
    fontSize: '20px',
  },
  '& .body-text p': {
    marginBottom: 0,
  },
  '& .profile-title': {
    [Breakpoints.TabletUp.mq]: {
      marginTop: '44px',
    },
    [Breakpoints.Mobile.mq]: {
      marginTop: '34px',
    },
  },
  '& .profile-text': {
    marginTop: '32px',
  },
  '& .contact-title': {
    marginTop: '20px',
    [Breakpoints.Mobile.mq]: {
      marginTop: '14px',
    },
  },
  '& .contact-info': {
    [Breakpoints.TabletUp.mq]: {
      marginBottom: '20px',
    },
    [Breakpoints.Mobile.mq]: {
      marginTop: '34px',
    },
    marginTop: 'auto',
  },
  '& .contact-container': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '30px',
    height: '100%',
    [Breakpoints.Mobile.mq]: {
      marginLeft: '0',
      paddingLeft: '0',
    },
  },
});

type ProfileProps = {
  // eslint-disable-next-line no-restricted-globals
  name?: string;
  jobTitle?: string;
  phone?: string;
  email?: string;
  backLink?: InternalPropTypes.Link;
  image?: InternalPropTypes.Image;
  type?: TypeProfileTypes;
  header?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
};

class Profile extends Component<ProfileProps> {
  static types = ProfileEnum;

  static defaultProps = {
    name: '',
    jobTitle: '',
    phone: '',
    email: '',
    backLink: null,
    image: null,
    type: this.types.PROFILE,
    header: null,
    bodyText: null,
  };

  render() {
    const {
      name,
      jobTitle,
      phone,
      email,
      image,
      backLink,
      type,
      header,
      bodyText,
    } = this.props;

    const phoneProps = generatePhoneProps(filterPhone(phone));
    const emailProps = generateEmailProps(filterEmail(email));

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { h: '400' },
      [ScreenSize.Tablet]: { w: '301' },
      [ScreenSize.Desktop]: { w: '333' },
    };

    return (
      <div css={styles}>
        <Grid2CellUnevenSplit
          leftCellCount={4}
          rightCellCount={type === Profile.types.PROFILE ? 7 : 8}
          snapAtBreakpoint={Breakpoints.MobileDown}
          onRenderCell1={() => (
            <div>
              {type === Profile.types.PROFILE ? (
                <>
                  <CallToAction
                    link={backLink}
                    type={CallToAction.types.LEFT_POINTING}
                  />

                  <Image image={image} sizes={sizes} />

                  <Header type={Header.types.PARTNER}>{name}</Header>

                  <Caption type={Caption.types.TITLEPROFILE}>
                    {jobTitle}
                  </Caption>

                  <BodyText type={BodyText.types.STATIC16} {...phoneProps}>
                    {phone}
                  </BodyText>

                  <BodyText type={BodyText.types.STATIC16} {...emailProps}>
                    {email}
                  </BodyText>
                </>
              ) : (
                <Image image={image} sizes={sizes} />
              )}
            </div>
          )}
          onRenderCell2={() => (
            <div className={`${type}-container`}>
              <div className={`${type}-title`}>
                {header && (
                  <BodyText
                    type={
                      type === Profile.types.PROFILE
                        ? BodyText.types.STATIC16
                        : BodyText.types.HEADER2TEXTGROUP
                    }
                  >
                    {header}
                  </BodyText>
                )}
              </div>
              <div className={`${type}-text`}>
                {bodyText && (
                  <BodyText
                    type={
                      type === Profile.types.PROFILE
                        ? BodyText.types.HEADER2TEXTGROUP
                        : BodyText.types.STATIC16
                    }
                  >
                    {bodyText}
                  </BodyText>
                )}
              </div>
              {type === Profile.types.CONTACT && (
                <div className="contact-info">
                  <BodyText type={BodyText.types.HEADER2TEXTGROUP}>
                    {name}
                  </BodyText>

                  <BodyText type={BodyText.types.STATIC16} {...emailProps}>
                    {email}
                  </BodyText>

                  <BodyText type={BodyText.types.STATIC16} {...phoneProps}>
                    {phone}
                  </BodyText>
                </div>
              )}
            </div>
          )}
        />
      </div>
    );
  }
}

export default Profile;
