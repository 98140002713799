import React, { Component } from 'react';
import { css } from '@emotion/react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import { colorEnum } from '../constants/colors';
import FileRequestForm from './common/molecules/FileRequestForm';
import Grid2CellUnevenSplit from './common/atoms/Grid2CellUnevenSplit';
import Header from './common/atoms/Header';
import BodyText from './common/atoms/BodyText';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';

const styles = css({
  paddingTop: '125px',
  paddingBottom: '125px',
  '& .file-request-header': {
    paddingLeft: '0px',
  },
  '& .block-container-active': {
    width: '100%',
    maxWidth: '100%',
    [Breakpoints.TabletDown.mq]: {
      marginTop: '32px',
    },
  },
  '& .block-container-not-active': {
    display: 'none',
    '& .location-container-active': {
      display: 'none',
    },
  },
  '& .button-pointer': {
    textAlign: 'left',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    display: 'flex',
    outline: 0,
    paddingBottom: '8px',
    paddingTop: '8px',
    width: 'max-content',
    '& .navigable-active': {
      color: colorEnum.white,
      fontSize: '24px',
      display: 'flex',
      marginBottom: 0,
    },
    '& .navigable-not-active': {
      color: colorEnum.gray,
      fontSize: '24px',
      display: 'flex',
      marginBottom: 0,
    },
    '& .pointer-arrow-not-active, .pointer-arrow-active': {
      height: '30px',
      width: '30px',
      marginLeft: '20px',
      backgroundColor: 'transparent',
    },
  },
  '& .file-request-outer-grid': {
    paddingLeft: '0px',
  },
  '& .outer-right-cell': {
    paddingTop: '30px',
  },
  '& .outer-left-cell': {
    paddingTop: '30px',
    '& .file-request-body-text': {
      width: '100%',
      paddingRight: '10%',
    },
  },
  '& .profile-contact': {
    marginBottom: '100px',
    width: '100%',
  },
});

type FileRequestComponentProps = {
  backgroundColor?: string;
  headerText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  emailMessage?: string;
  resubmitMessage?: InternalPropTypes.RichText;
  failedSubmitMessage?: InternalPropTypes.RichText;
  submitButtonText?: InternalPropTypes.RichText;
  namePlaceholder?: string;
  emailPlaceholder?: string;
  sendingMessage?: InternalPropTypes.RichText;
  links?: { text: string; url: string }[];
  mailContent?: InternalPropTypes.MailContent;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  submitFileRequest?: (...args: any[]) => any;
};

class FileRequestComponent extends Component<FileRequestComponentProps> {
  static defaultProps = {
    backgroundColor: null,
    headerText: null,
    bodyText: null,
    emailMessage: null,
    resubmitMessage: null,
    failedSubmitMessage: null,
    namePlaceholder: null,
    emailPlaceholder: null,
    submitButtonText: null,
    sendingMessage: null,
    links: null,
    mailContent: null,
    submitFileRequest: () => null,
  };

  render() {
    const {
      backgroundColor,
      headerText,
      bodyText,
      emailMessage,
      resubmitMessage,
      failedSubmitMessage,
      submitButtonText,
      namePlaceholder,
      emailPlaceholder,
      sendingMessage,
      links,
      mailContent,
      submitFileRequest,
    } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <Header
              type={Header.types.KEYFIGURE}
              className="file-request-header"
            >
              {headerText}
            </Header>
            <Grid2CellUnevenSplit
              leftCellCount={7}
              rightCellCount={5}
              className="file-request-outer-grid"
              onRenderCell1={() => (
                <div className="outer-left-cell">
                  <BodyText
                    type={BodyText.types.LIGHT14}
                    className="file-request-body-text"
                  >
                    {bodyText}
                  </BodyText>
                </div>
              )}
              onRenderCell2={() => (
                <div className="outer-right-cell">
                  <FileRequestForm
                    emailMessage={emailMessage}
                    resubmitMessage={resubmitMessage}
                    failedSubmitMessage={failedSubmitMessage}
                    submitButtonText={submitButtonText}
                    namePlaceholder={namePlaceholder}
                    emailPlaceholder={emailPlaceholder}
                    sendingMessage={sendingMessage}
                    links={links}
                    mailContent={mailContent}
                    submitFileRequest={submitFileRequest}
                  />
                </div>
              )}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default FileRequestComponent;
