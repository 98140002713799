// eslint-disable-next-line max-classes-per-file
import * as Contentful from 'contentful';
import * as ContentfulRichText from '@contentful/rich-text-types';
import {
  ContentTypeEnum,
  PagePresentationEnum,
} from '../constants/cms-constants';
import {
  BlockContentEntry,
  isCmsEntity,
  PageReference,
  PublishedStateAggregation,
} from '../constants/internal-types';
import {
  addLanguagePrefixToSlug,
  getPageUrlFromPage,
  getPageUrlFromPageReference,
} from './url';
import * as ContentState from '../constants/contentstate';
import { LANGUAGES } from '../constants/language';
import ContentfulStateCalculator, {
  EntryStatePairType,
} from './ContentfulStateCalculator';
import * as CmsTypes from '../types/ts/contentful';
import { AppConfigType } from '../client-server-utils/appConfig';
import { ContentfulResult } from '../client-server-utils/connectors/contentful';

const getCmsEntityContentType = (cmsEntity: Contentful.Entry<any>) =>
  cmsEntity.sys.contentType.sys.id;

const getSingleEntityFieldByName = (
  cmsEntity: Contentful.Entry<any> | Contentful.Asset,
  fieldName: string,
) => cmsEntity.fields[fieldName];

const getMultipleEntityFieldsByName = (
  cmsEntity: Contentful.Entry<any> | Contentful.Asset,
  fieldNames: Array<string>,
) =>
  fieldNames.reduce((acc, fieldName) => {
    acc[fieldName] = getSingleEntityFieldByName(cmsEntity, fieldName);
    return acc;
  }, {});

const getCmsEntityFieldByName = (
  cmsEntity: Contentful.Entry<any> | Contentful.Asset,
  fieldNameOrNames: string | Array<string>,
) =>
  Array.isArray(fieldNameOrNames)
    ? getMultipleEntityFieldsByName(
        cmsEntity,
        fieldNameOrNames as Array<string>,
      )
    : getSingleEntityFieldByName(cmsEntity, fieldNameOrNames);

const getCmsEntityFieldByNameOrDefault = (
  cmsEntity: Contentful.Entry<any>,
  fieldName: string,
  defaultReturn: any,
) => {
  const result = getCmsEntityFieldByName(cmsEntity, fieldName);
  return result || defaultReturn;
};
const getLanguageNameFromLocale = (locale: string) =>
  LANGUAGES.find((l) => l.iso === locale)?.name;

const includes = <T>(array: Array<T>, thing: T) => array.indexOf(thing) >= 0;

function verifyCmsContentType(
  cmsEntity: Contentful.Entry<any>,
  expectedContentTypes: string | Array<string>,
) {
  if (!Array.isArray(expectedContentTypes)) {
    // eslint-disable-next-line no-param-reassign
    expectedContentTypes = [expectedContentTypes];
  }

  if (!isCmsEntity(cmsEntity)) {
    throw new TypeError(
      'verifyCmsContentType failed. Provided cms entity was not a cms entity',
    );
  } else if (
    !includes(expectedContentTypes, getCmsEntityContentType(cmsEntity))
  ) {
    throw new TypeError(
      `verifyCmsContentType failed. Provided cms entity had content type ${getCmsEntityContentType(
        cmsEntity,
        // eslint-disable-next-line no-undef
      )} and not the expected content type ${expectedContentTypes}`,
    );
  }
}

function verifyCmsControlOption(cmsControlOption: CmsTypes.TypeControlOption) {
  verifyCmsContentType(cmsControlOption, ContentTypeEnum.CONTROLOPTION);
  const customOption = getCmsEntityFieldByName(
    cmsControlOption,
    'customOption',
  );
  const fixedOption = getCmsEntityFieldByName(cmsControlOption, 'fixedOption');
  if (fixedOption !== 'BackgroundColor' && customOption && fixedOption) {
    throw new TypeError(
      `verifyCmsControlOption failed. Provided cms control option entity was both marked as a custom option and had a Fixed option value selected. Choose one and one only`,
    );
  }
  const positionValue = getCmsEntityFieldByName(
    cmsControlOption,
    'valuePosition',
  );
  const freezeInBreakPointValue = getCmsEntityFieldByName(
    cmsControlOption,
    'valueFreezeInBreakpoint',
  );
  const colorValue = customOption
    ? getCmsEntityFieldByName(cmsControlOption, 'valueCustom')
    : getCmsEntityFieldByName(cmsControlOption, 'valueColor');
  const customValue = getCmsEntityFieldByName(cmsControlOption, 'valueCustom');
  const name = getCmsEntityFieldByName(cmsControlOption, 'name');

  if (fixedOption) {
    if (fixedOption === 'Position' && !positionValue) {
      throw new ReferenceError(
        'verifyCmsControlOption failed. Control option had fixed option position but no position value specified',
      );
    } else if (
      fixedOption === 'FreezeInBreakpoint' &&
      !freezeInBreakPointValue
    ) {
      throw new ReferenceError(
        'verifyCmsControlOption failed. Control option had fixed option freeze in breakpoint but no freeze in breakpoint value specified',
      );
    } else if (
      fixedOption === 'BackgroundColor' &&
      !colorValue &&
      customOption
    ) {
      throw new ReferenceError(
        'verifyCmsControlOption failed. Control option had fixed option background color and customOption set but no custom value specified',
      );
    } else if (
      fixedOption === 'BackgroundColor' &&
      !colorValue &&
      !customOption
    ) {
      throw new ReferenceError(
        'verifyCmsControlOption failed. Control option had fixed option background color and not customOption set but no color value specified',
      );
    }
  } else if (customOption) {
    if (!name) {
      throw new ReferenceError(
        'verifyCmsControlOption failed. Control option cannot be marked as custom without having a name',
      );
    } else if (!customValue) {
      throw new ReferenceError(
        'verifyCmsControlOption failed. Control option had custom option but no custom value specified',
      );
    }
  } else {
    throw new TypeError(
      `verifyCmsControlOption failed. Control option must be either set to fixed option or marked as custom option with a name`,
    );
  }
  return true;
}

function verifyCmsPage(cmsPage: CmsTypes.TypePage) {
  const modalSlug = getCmsEntityFieldByName(cmsPage, 'modalSlug');
  const pageSlug = getCmsEntityFieldByName(cmsPage, 'pageSlug');

  if (!modalSlug && !pageSlug) {
    throw new TypeError(
      `verifyCmsPage failed. Page must have either a modalSlug or a pageSlug specified. Contentful entry id ${cmsPage.sys.id}`,
    );
  }
  if (modalSlug && pageSlug) {
    throw new TypeError(
      `verifyCmsPage failed. Page cannot have both a modalSlug and a pageSlug specified. Contentful entry id ${cmsPage.sys.id}`,
    );
  }
}

export default class ContentfulPropMapper {
  appConfig: AppConfigType;

  constructor(appConfig?: AppConfigType) {
    this.appConfig = appConfig;
  }

  // eslint-disable-next-line class-methods-use-this
  mapImageFile(cmsMediaImage: Contentful.Asset) {
    let result;
    if (cmsMediaImage) {
      const file = getCmsEntityFieldByName(cmsMediaImage, 'file');
      if (file) {
        result = {
          assetName: getCmsEntityFieldByName(cmsMediaImage, 'title'),
          url: file?.url,
          heightInPx: file?.details.image?.height,
          widthInPx: file?.details.image?.width,
          sizeInBytes: file?.details.size,
        };
      }
    }
    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  mapVideoFile(cmsVideoFile: Contentful.Asset) {
    let result;
    if (cmsVideoFile) {
      const file = getCmsEntityFieldByName(cmsVideoFile, 'file');
      result = {
        title: getCmsEntityFieldByName(cmsVideoFile, 'title'),
        url: file.url,
        sizeInBytes: file.details.size,
      };
    }
    return result;
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  mapSnippet(cmsSnippet: CmsTypes.TypeSnippet, locale: string) {
    let result;
    if (cmsSnippet) {
      result = getCmsEntityFieldByName(cmsSnippet, ['name', 'text']);
      result.language = getLanguageNameFromLocale(locale);
    }
    return result;
  }

  // eslint-disable-next-line consistent-return, class-methods-use-this
  mapControlOption(cmsControlOption: CmsTypes.TypeControlOption) {
    try {
      if (
        isCmsEntity(cmsControlOption) &&
        verifyCmsControlOption(cmsControlOption)
      ) {
        return Object.assign(
          getCmsEntityFieldByName(cmsControlOption, [
            'name',
            'fixedOption',
            'customOption',
            'valueFreezeInBreakpoint',
            'valueColor',
            'valuePosition',
            'list3GroupsType',
            'heroType',
            'wellType',
            'quoteType',
            'headerTextGroupType',
            'callToActionType',
            'callToActionWithHeaderType',
            'keyFigureGridType',
            'profileGridType',
            'profileType',
            'valueCustom',
          ]),
        );
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  mapPublishedState(
    entry: Contentful.Entry<any>,
    stateCalculator: ContentfulStateCalculator,
    publishedState: PublishedStateAggregation,
  ) {
    if (publishedState) {
      // eslint-disable-next-line no-param-reassign
      publishedState.state = stateCalculator.getEntryState(entry);
      // eslint-disable-next-line no-param-reassign
      publishedState.aggregatedState =
        stateCalculator.getAggregatedEntryState(entry);
      if (publishedState.aggregatedState !== ContentState.PUBLISHED) {
        // eslint-disable-next-line no-param-reassign
        publishedState.remarks = publishedState.remarks || [];
        stateCalculator
          .getNotPublishedEntries(entry)
          .forEach(({ entryId, state }: EntryStatePairType) => {
            publishedState?.remarks?.push({
              message: `Entry ${entryId} has state ${state}`,
              state,
              entryId,
            });
          });
      }
    }
    return publishedState;
  }

  mapBlockContent(
    cmsBlockContent: Array<CmsTypes.TypeBlockFieldContent>,
    locale: string,
  ): Array<BlockContentEntry> {
    return (cmsBlockContent || []).filter(isCmsEntity).map((entry) => ({
      cmsType: getCmsEntityContentType(entry as Contentful.Entry<any>),
      content: this.mapBlockContentEntry(entry, locale),
    }));
  }

  mapImage(cmsImage: CmsTypes.TypeImage) {
    if (!isCmsEntity(cmsImage)) return null;

    const imgixQueryString =
      getCmsEntityFieldByName(cmsImage, 'imgixQueryString') || '';

    const imgixImage = getCmsEntityFieldByName(cmsImage, 'imgixImage');
    const contentfulImage = this.mapImageFile(
      getCmsEntityFieldByName(cmsImage, 'image'),
    );

    return {
      name: getCmsEntityFieldByName(cmsImage, 'name'),
      alternateText: getCmsEntityFieldByName(cmsImage, 'altText'),
      contentfulImage,
      imgixImage,
      imgixQueryString,
      contentfulEntryId: cmsImage.sys.id,
    };
  }

  mapLink(cmsLink: CmsTypes.TypeLink, locale: string) {
    let result;
    if (isCmsEntity(cmsLink)) {
      const pageReference = this.mapPageReference(
        getCmsEntityFieldByName(cmsLink, 'pageReference'),
        locale,
      );
      result = Object.assign(
        getCmsEntityFieldByName(cmsLink, [
          'name',
          'text',
          'url',
          'openInNewWindowOrTab',
        ]),
        {
          language: getLanguageNameFromLocale(locale),
          image: this.mapImage(getCmsEntityFieldByName(cmsLink, 'image')),
          pageReference,
        },
      );
      result.linkTo = pageReference
        ? getPageUrlFromPageReference(pageReference)
        : result.url || '';
    }
    return result;
  }

  mapVideo(cmsVideo) {
    let result;
    if (cmsVideo) {
      result = {
        name: getCmsEntityFieldByName(cmsVideo, 'name'),
        poster: this.mapImage(getCmsEntityFieldByName(cmsVideo, 'poster')),
        videos: getCmsEntityFieldByNameOrDefault(cmsVideo, 'video', []).map(
          this.mapVideoFile,
        ),
        ariaLabel: getCmsEntityFieldByName(cmsVideo, 'ariaLabel'),
      };
    }
    return result;
  }

  mapBlock(
    cmsBlock: CmsTypes.TypeBlock,
    locale: string,
    stateCalculator: ContentfulStateCalculator | undefined = undefined,
  ) {
    let result;
    if (isCmsEntity(cmsBlock)) {
      verifyCmsContentType(cmsBlock, ContentTypeEnum.BLOCK);
      result = Object.assign(
        getCmsEntityFieldByName(cmsBlock, ['name', 'bodyText', 'type']),
        {
          language: getLanguageNameFromLocale(locale),
          header: {
            main: getCmsEntityFieldByName(cmsBlock, 'mainHeader'),
            light: getCmsEntityFieldByName(cmsBlock, 'lightHeader'),
          },
          contentfulEntryId: cmsBlock.sys.id,
          controlOptionsV2: getCmsEntityFieldByName(cmsBlock, 'controlOptions'),
          controlOptions: (
            getCmsEntityFieldByName(cmsBlock, 'configuration') || []
          ).map(this.mapControlOption),
          link: this.mapLink(getCmsEntityFieldByName(cmsBlock, 'link'), locale),
          content: this.mapBlockContent(
            getCmsEntityFieldByName(cmsBlock, 'content'),
            locale,
          ),
          state: stateCalculator
            ? this.mapPublishedState(cmsBlock, stateCalculator, {})
            : undefined,
        },
      );
    }
    return result;
  }

  mapBlockContentEntry(
    cmsBlockContent: CmsTypes.TypeBlockFieldContent,
    locale: string,
  ) {
    const contentType = getCmsEntityContentType(
      cmsBlockContent as Contentful.Entry<any>,
    );
    switch (contentType) {
      case ContentTypeEnum.SNIPPET:
        return this.mapSnippet(cmsBlockContent as CmsTypes.TypeSnippet, locale);
      case ContentTypeEnum.IMAGE:
        return this.mapImage(cmsBlockContent as CmsTypes.TypeImage);
      case ContentTypeEnum.VIDEO:
        return this.mapVideo(cmsBlockContent);
      case ContentTypeEnum.PERSON:
        return this.mapPerson(cmsBlockContent as CmsTypes.TypePerson, locale);
      case ContentTypeEnum.MEDIAITEM:
        return this.mapMediaItem(
          cmsBlockContent as CmsTypes.TypeMediaItem,
          locale,
        );
      case ContentTypeEnum.EVENT:
        return this.mapEvent(cmsBlockContent as CmsTypes.TypeEvent, locale);
      case ContentTypeEnum.LINK:
        return this.mapLink(cmsBlockContent as CmsTypes.TypeLink, locale);
      case ContentTypeEnum.MAILCONTENT:
        return this.mapMailContent(
          cmsBlockContent as CmsTypes.TypeMailContent,
          locale,
        );
      default:
        throw new RangeError(
          `interpretBlockContent could find a mapper function for contentful content type ${contentType}`,
        );
    }
  }

  mapPerson(cmsPerson: CmsTypes.TypePerson, locale: string) {
    let result;
    if (cmsPerson) {
      result = Object.assign(
        getCmsEntityFieldByName(cmsPerson, [
          'name',
          'description',
          'quote',
          'email',
          'jobTitle',
          'phone',
        ]),
        {
          language: getLanguageNameFromLocale(locale),
          image: this.mapImage(getCmsEntityFieldByName(cmsPerson, 'image')),
          link: this.mapLink(
            getCmsEntityFieldByName(cmsPerson, 'link'),
            locale,
          ),
        },
      );
    }
    return result;
  }

  mapMediaItem(cmsMediaItem: CmsTypes.TypeMediaItem, locale: string) {
    let result;
    if (isCmsEntity(cmsMediaItem)) {
      const cmsImage = getCmsEntityFieldByName(cmsMediaItem, 'image');
      let image;
      if (isCmsEntity(cmsImage)) {
        image = this.mapImage(cmsImage);
      }
      result = Object.assign(
        getCmsEntityFieldByName(cmsMediaItem, [
          'name',
          'header',
          'lightHeader',
          'bodyText',
          'backgroundColor',
          'openInNewWindowOrTab',
        ]),
        {
          language: getLanguageNameFromLocale(locale),
          image,
          link: this.mapLink(
            getCmsEntityFieldByName(cmsMediaItem, 'link'),
            locale,
          ),
          sender: this.mapPerson(
            getCmsEntityFieldByName(cmsMediaItem, 'sender'),
            locale,
          ),
        },
      );
    }
    return result;
  }

  mapEvent(cmsEvent: CmsTypes.TypeEvent, locale: string) {
    let result;
    if (isCmsEntity(cmsEvent)) {
      const cmsImage = getCmsEntityFieldByName(cmsEvent, 'promoImage');
      let image;
      if (isCmsEntity(cmsImage)) {
        image = this.mapImage(cmsImage);
      }
      result = Object.assign(
        getCmsEntityFieldByName(cmsEvent, [
          'name',
          'title',
          'geoLocation',
          'address',
          'description',
        ]),
        {
          teaser: getCmsEntityFieldByName(cmsEvent, 'promoTeaser'),
          language: getLanguageNameFromLocale(locale),
          image,
          onlineLocation: this.mapLink(
            getCmsEntityFieldByName(cmsEvent, 'onlineLocation'),
            locale,
          ),
          date: new Date(getCmsEntityFieldByName(cmsEvent, 'date')).getTime(),
        },
      );
    }

    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  mapMailContent(cmsMailContent: CmsTypes.TypeMailContent, locale: string) {
    let result;
    if (isCmsEntity(cmsMailContent)) {
      result = Object.assign(
        getCmsEntityFieldByName(cmsMailContent, [
          'subject',
          'title',
          'description',
          'articleHeader',
        ]),
        {
          languages: getLanguageNameFromLocale(locale),
        },
      );
    }
    return result;
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  mapPageReference(cmsPage: CmsTypes.TypePage, locale: string) {
    let result;
    if (cmsPage?.fields) {
      result = getCmsEntityFieldByName(cmsPage, [
        'name',
        'pageSlug',
        'modalSlug',
        'presentation',
        'language',
      ]);
      result.language = getLanguageNameFromLocale(locale);
    }
    if (cmsPage && !cmsPage?.fields) {
      const newobj: any = { fields: cmsPage };
      result = getCmsEntityFieldByName(newobj, [
        'name',
        'pageSlug',
        'modalSlug',
        'presentation',
        'language',
      ]);
      result.language = getLanguageNameFromLocale(locale);
    }

    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  mapAlternatePages(
    cmsPage: CmsTypes.TypePage,
    pageInAllLanguages: ContentfulResult,
  ) {
    const alternatePages = [];
    const presentation = getCmsEntityFieldByName(cmsPage, 'presentation');

    if (!cmsPage.fields.modalSlug) {
      Object.entries(
        (pageInAllLanguages.content as any).items[0].fields.pageSlug,
      ).forEach(([language, pageSlug]) => {
        if (language !== pageInAllLanguages.language) {
          alternatePages.push({
            pageSlug,
            language,
            modalSlug: '',
            presentation,
            name: '',
          } as PageReference);
        }
      });
    }
    // eslint-disable-next-line no-param-reassign
    cmsPage.fields.alternatePages = alternatePages;
  }

  mapPage(
    cmsPage: CmsTypes.TypePage,
    locale: string,
    defaultTeasers: { twitter: any; openGraph: any } | null = null,
    cmsPageContentResponse: CmsTypes.TypePage | null = null,
    pageInAllLanguages: ContentfulResult | null = null,
  ) {
    const stateCalculator = new ContentfulStateCalculator(
      cmsPageContentResponse as Contentful.Entry<any>,
    );
    const publishedState: PublishedStateAggregation = {
      state: null,
      aggregatedState: null,
      remarks: [],
    };
    if (pageInAllLanguages) {
      this.mapAlternatePages(cmsPage, pageInAllLanguages);
    }
    try {
      verifyCmsPage(cmsPage);
    } catch (e) {
      publishedState?.remarks?.push({
        message: `Caught error during verification of CMS page: ${e.message}`,
        stackTrace: (e as Error).stack,
      });
      console.error(e);
    }
    const presentation = getCmsEntityFieldByName(cmsPage, 'presentation');
    const result = Object.assign(
      getCmsEntityFieldByName(cmsPage, ['name', 'menuColor']),
      {
        contentfulEntryId: cmsPage.sys.id,
        controlOptions: (
          getCmsEntityFieldByName(cmsPage, 'configuration') || []
        ).map(this.mapControlOption),
        twitterImage: this.mapImage(
          getCmsEntityFieldByName(cmsPage, 'twitterImage'),
        ),
        openGraphImage: this.mapImage(
          getCmsEntityFieldByName(cmsPage, 'openGraphImage'),
        ),
        defaultTwitterImage: defaultTeasers?.twitter,
        defaultOpenGraphImage: defaultTeasers?.openGraph,
        slug: {
          page: getCmsEntityFieldByName(cmsPage, 'pageSlug'),
          modal: getCmsEntityFieldByName(cmsPage, 'modalSlug'),
        },
        presentation,
        isModalPage: presentation === PagePresentationEnum.MODAL,
        isSystemPage: presentation === PagePresentationEnum.SYSTEM,
        state: this.mapPublishedState(cmsPage, stateCalculator, publishedState),
        blocks: getCmsEntityFieldByNameOrDefault(cmsPage, 'blocks', [])
          .map((b) => this.mapBlock(b, locale, stateCalculator))
          .filter((b) => !!b),
        seo: {
          title: getCmsEntityFieldByName(cmsPage, 'seoPageTitle'),
          description: getCmsEntityFieldByName(cmsPage, 'seoPageDescription'),
          noIndex: getCmsEntityFieldByName(cmsPage, 'noIndexSeo'),
        },
        alternatePages: getCmsEntityFieldByNameOrDefault(
          cmsPage,
          'alternatePages',
          [],
        ).map((pageReference) => {
          const newRef = pageReference;
          newRef.pageSlug = `/${addLanguagePrefixToSlug(
            newRef.language,
            newRef.pageSlug,
          )}`;
          return this.mapPageReference(newRef, newRef.language);
        }),
        language: getLanguageNameFromLocale(locale),
      },
    );
    result.linkTo = getPageUrlFromPage(result);
    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  mapRichTextToBlock(embeddedEntryNode: ContentfulRichText.Node) {
    const block = this.mapBlock(
      embeddedEntryNode.data.target,
      embeddedEntryNode.data.target.sys.locale,
    );
    return block;
  }

  mapRoot(
    cmsRoot: CmsTypes.TypeRoot,
    allCmsPages: Array<CmsTypes.TypePage>,
    locale: string,
  ) {
    verifyCmsContentType(cmsRoot, ContentTypeEnum.ROOT);
    const cmsPages = allCmsPages.filter(
      (page) =>
        page.fields.presentation === PagePresentationEnum.PAGE ||
        getCmsEntityContentType(page) === ContentTypeEnum.PAGE,
    );
    const cmsModalPages = allCmsPages.filter(
      (page) => page.fields.presentation === PagePresentationEnum.MODAL,
    );
    const cmsSystemPages = allCmsPages.filter(
      (page) => page.fields.presentation === PagePresentationEnum.SYSTEM,
    );
    const defaultTeasers = {
      twitter: this.mapImage(
        getCmsEntityFieldByName(cmsRoot, 'defaultTwitterImage'),
      ),
      openGraph: this.mapImage(
        getCmsEntityFieldByName(cmsRoot, 'defaultOpenGraphImage'),
      ),
    };
    return {
      images: {
        logo: {
          white: this.mapImage(
            getCmsEntityFieldByName(cmsRoot, 'companyLogoWhite'),
          ),
          black: this.mapImage(
            getCmsEntityFieldByName(cmsRoot, 'companyLogoBlack'),
          ),
        },
        name: {
          white: this.mapImage(
            getCmsEntityFieldByName(cmsRoot, 'companyNameWhite'),
          ),
          black: this.mapImage(
            getCmsEntityFieldByName(cmsRoot, 'companyNameBlack'),
          ),
        },
      },
      footer: {
        contact: {
          header: getCmsEntityFieldByName(cmsRoot, 'footerContactHeader'),
          link: this.mapLink(
            getCmsEntityFieldByName(cmsRoot, 'footerContactLink'),
            locale,
          ),
        },
        finePrint: getCmsEntityFieldByNameOrDefault(
          cmsRoot,
          'footerFinePrint',
          [],
        ).map((mi) => this.mapMediaItem(mi, locale)),
        navigationLinks: {
          small: getCmsEntityFieldByNameOrDefault(
            cmsRoot,
            'footerSmallNavigationLinks',
            [],
          ).map((l) => this.mapLink(l, locale)),
          large: getCmsEntityFieldByNameOrDefault(
            cmsRoot,
            'footerLargeNavigationLinks',
            [],
          ).map((l) => this.mapLink(l, locale)),
        },
        soMe: getCmsEntityFieldByNameOrDefault(
          cmsRoot,
          'footerSoMeLink',
          [],
        ).map((l) => this.mapLink(l, locale)),
      },
      pages: cmsPages.map((p) => this.mapPage(p, locale, defaultTeasers)),
      modalPages: cmsModalPages.map((p) =>
        this.mapPage(p, locale, defaultTeasers),
      ),
      systemPages: cmsSystemPages.map((p) =>
        this.mapPage(p, locale, defaultTeasers),
      ),
      menuItems: getCmsEntityFieldByNameOrDefault(cmsRoot, 'menuItems', []).map(
        (p) => this.mapPage(p, locale),
      ),
      cookie: {
        message: getCmsEntityFieldByName(cmsRoot, 'cookieConsentMessage'),
        policyVersion: getCmsEntityFieldByName(cmsRoot, 'cookiePolicyVersion'),
        acceptAllButtonLabel: getCmsEntityFieldByName(
          cmsRoot,
          'cookieAcceptAllButtonLabel',
        ),
        acceptNecessaryButtonLabel: getCmsEntityFieldByName(
          cmsRoot,
          'cookieAcceptNecessaryButtonLabel',
        ),
        saveSettingsButtonLabel: getCmsEntityFieldByName(
          cmsRoot,
          'cookieSaveSettingsButtonLabel',
        ),
      },
    };
  }
}
