import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import NextPageMolecule from './common/molecules/NextPage';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import { TypeSEOWeightTypes } from '../constants/cms-constants';

const styles = {
  '&.next-page-component': {
    [Breakpoints.Mobile.mq]: {
      paddingTop: '104px',
      paddingBottom: '120px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingTop: '104px',
      paddingBottom: '144px',
    },
    [Breakpoints.DesktopUp.mq]: {
      paddingTop: '128px',
      paddingBottom: '144px',
    },
  },
};

type NextPageProps = {
  image?: InternalPropTypes.Image;
  link?: InternalPropTypes.Link;
  bodyText?: InternalPropTypes.RichText;
  headerText?: InternalPropTypes.RichText;
  backgroundColor?: string;
  seoWeight?: TypeSEOWeightTypes;
};

class NextPage extends Component<NextPageProps> {
  static defaultProps = {
    image: null,
    link: null,
    bodyText: null,
    headerText: null,
    backgroundColor: null,
    seoWeight: 'h3',
  };

  render() {
    const { backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="next-page-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <NextPageMolecule {...this.props} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default NextPage;
