import { Component } from 'react';
import { css } from '@emotion/react';

import { compose } from 'recompose';
import BodyText from '../atoms/BodyText';

import * as InternalPropTypes from '../../../constants/internal-types';
import withImageHelperFactory, {
  WithImageHelperFactoryProps,
} from '../../../utils/hocs/withImageHelperFactory';

const styles = css({
  '& .entireDocument': {},
  '& .openGraphCard': {
    boxShadow: '0 0 0 1px rgb(0 0 0 / 15%), 0 2px 3px rgb(0 0 0 / 20%)',
    overflow: 'hidden',
    position: 'relative',
  },

  '& .entireCard': {
    width: 'fit-content',
    maxWidth: 'calc(100% - 24px)',
    margin: '0 auto',
    '& .presentingCard': {
      borderBottomColor: 'rgba(0, 0, 0, .15)',
      borderBottom: '1px solid',
      paddingLeft: '10px',
      '& .caption': {
        fontSize: '1.2rem',
        margin: '10px',
      },
    },
    '& .thumbnailPreview': {
      width: 'fit-content',
      margin: '16px',
      position: 'relative',
      '& .postThumbnail': {
        display: 'block',
        height: '270px',
        objectFit: 'cover',
        width: '520px',
      },
      '& .errorIndicator': {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '1.2rem',
        color: 'red',
      },
      '& .postInfo': {
        padding: '10px',
        width: '500px',
        '& .postTitle': {
          fontSize: '1rem',
          lineHeight: '1.5',
          fontWeight: '600',
          color: 'rgba(0, 0, 0, .9)',
          paddingBottom: '2px',
        },
        '& .postProvider': {
          fontSize: '0.75rem',
          lineHeight: '1.33333',
          fontWeight: '400',
          color: 'rgba(0, 0, 0, .6)',
        },
      },
    },
    '& .errorsAndWarnings': {
      '& .errors': {
        fontSize: '1.2rem',
        margin: '10px',
        paddingLeft: '18px',
      },
      '& .warnings': {
        fontSize: '1.2rem',
        margin: '10px',
        paddingLeft: '18px',
      },
    },
  },
  body: {
    fontFamily:
      ' -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif',
  },
  p: {
    margin: '0',
  },
});

type OpenGraphPreviewProps = {
  image?: InternalPropTypes.Image;
  caption?: InternalPropTypes.RichText;
  postTitle?: InternalPropTypes.RichText;
  postProvider?: InternalPropTypes.RichText;
  errorText?: InternalPropTypes.RichText;
  warningText?: InternalPropTypes.RichText;
  indicator?: InternalPropTypes.RichText;
};

type OpenGraphPreviewInnerProps = OpenGraphPreviewProps &
  WithImageHelperFactoryProps;

class OpenGraphPreview extends Component<OpenGraphPreviewInnerProps> {
  static defaultProps = {
    image: null,
    caption: null,
    postTitle: null,
    postProvider: null,
    errorText: null,
    warningText: null,
    indicator: null,
  };

  render() {
    const {
      image,
      caption,
      postTitle,
      postProvider,
      errorText,
      warningText,
      indicator,
      imageHelperFactory,
    } = this.props;

    const imageUrl = imageHelperFactory.createImageHelper(image).getImageUrl();

    return (
      <div className="entireDocument" css={styles}>
        <section className="entireCard openGraphCard">
          <header className="presentingCard">
            <BodyText className="caption" type={BodyText.types.LIGHT12}>
              {caption}
            </BodyText>
          </header>
          <section className="thumbnailPreview openGraphCard">
            <img
              src={imageUrl}
              className="postThumbnail"
              alt="OpenGraph preview"
            />
            <BodyText className="errorIndicator" type={BodyText.types.LIGHT12}>
              {indicator}
            </BodyText>
            <section className="postInfo">
              <BodyText className="postTitle" type={BodyText.types.LIGHT12}>
                {postTitle}
              </BodyText>
              <BodyText className="postProvider" type={BodyText.types.LIGHT12}>
                {postProvider}
              </BodyText>
            </section>
          </section>
          <section className="errorsAndWarnings">
            <BodyText className="errors" type={BodyText.types.LIGHT12}>
              {errorText}
            </BodyText>
            <BodyText className="warnings" type={BodyText.types.LIGHT12}>
              {warningText}
            </BodyText>
          </section>
        </section>
      </div>
    );
  }
}

export default compose<OpenGraphPreviewInnerProps, OpenGraphPreviewProps>(
  withImageHelperFactory,
)(OpenGraphPreview);
