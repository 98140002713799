import { Component, Fragment } from 'react';
import { css } from '@emotion/react';

import Header from '../atoms/Header';
import Image from '../atoms/Image';
import BodyText from '../atoms/BodyText';
import Grid2CellMedia from '../atoms/Grid2CellMedia';
import NwLink from '../NwLink';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import { colorEnum } from '../../../constants/colors';
import { ImgixParams } from '../../../types/ts/imgixQuery';

const styles = css({
  paddingTop: '30px',
  '& .cuecard-content': {
    position: 'relative',
    width: '100%',
    [Breakpoints.Mobile.mq]: {
      maxWidth: '360px',
    },
    '& .bodytext': {
      color: colorEnum.black,
      opacity: '56%',
      paddingTop: '18px',
    },
    '& .cue': {},
    '& .antihero': {
      color: '#f7f6f5',
      zIndex: -1,
      position: 'absolute',
      top: '-57px',
      left: '35px',
    },
  },
  '& .media-cell': {
    flex: '0 0 64px',
    [Breakpoints.DesktopUp.mq]: {
      marginRight: '25px',
      textAlign: 'center',
    },
    '& img': {
      maxWidth: '40px',
      maxHeight: '40px',
      objectFit: 'cover',
    },
  },
  '& a:hover': {
    textDecoration: 'none',
  },
});

type CueCardProps = {
  image?: InternalPropTypes.Image;
  header?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
};

class CueCard extends Component<CueCardProps> {
  static defaultProps = {
    image: null,
    header: null,
    bodyText: null,
    lightHeader: null,
    link: null,
  };

  render() {
    const { image, header, bodyText, link, lightHeader } = this.props;
    const WrapTag = link?.linkTo ? NwLink : Fragment;
    // Have to return empty object for Fragment
    const wrapProps = link?.linkTo
      ? { to: link.linkTo, openInNewWindowOrTab: link.openInNewWindowOrTab }
      : {};

    const sizes: ImgixParams = { w: '40', h: '40' };

    return (
      <div css={styles}>
        <WrapTag {...wrapProps}>
          <Grid2CellMedia
            onRenderMediaCell={() =>
              image && <Image image={image} sizes={sizes} />
            }
            onRenderContentCell={() => (
              <div className="cuecard-content">
                <Header type={Header.types.PAGE}>{lightHeader}</Header>

                <Header
                  seoWeight="h3"
                  className="title"
                  type={Header.types.NAMEBASE}
                >
                  {header}
                </Header>

                <BodyText className="bodytext" type={BodyText.types.STATIC16}>
                  {bodyText}
                </BodyText>
              </div>
            )}
          />
        </WrapTag>
      </div>
    );
  }
}

export default CueCard;
