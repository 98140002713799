const ControlOptions = {
  FREEZEINBREAKPOINT: 'FreezeInBreakpoint',
  BACKGROUNDCOLOR: 'BackgroundColor',
  POSITION: 'Position',
  LIST3GROUPSTYPE: 'List3GroupsType',
  HEROTYPE: 'HeroType',
  WELLTYPE: 'WellType',
  QUOTETYPE: 'QuoteType',
  HEADERTEXTGROUPTYPE: 'HeaderTextGroupType',
  CALLTOACTIONTYPE: 'CallToActionType',
  CALLTOACTIONWITHHEADERTYPE: 'CallToActionWithHeaderType',
  KEYFIGUREGRIDTYPE: 'KeyFigureGridType',
  PROFILEGRIDTYPE: 'ProfileGridType',
  PROFILETYPE: 'ProfileType',
  SEOWEIGHT: 'SEOWeight',
};

module.exports = ControlOptions;
