import { Component } from 'react';
import { css } from '@emotion/react';

import Header from '../atoms/Header';
import Caption from '../atoms/Caption';
import BodyText from '../atoms/BodyText';
import Title from '../atoms/Title';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import { HeroTypeEnum, TypeHeroTypes } from '../../../constants/cms-constants';
import TitleTypes from '../../../constants/title-types';

const styles = css({
  '& .caption': {
    opacity: '0.5',
    position: 'relative',
    [Breakpoints.Mobile.mq]: {
      marginBottom: '20px',
    },
    [Breakpoints.Tablet.mq]: {
      marginBottom: '18px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginBottom: '18px',
    },
  },
  '& .header-text': {
    [Breakpoints.Mobile.mq]: {
      marginBottom: '18px',
    },
    [Breakpoints.TabletUp.mq]: {
      marginBottom: '24px',
    },
  },
  '& .body-text': {
    [Breakpoints.Mobile.mq]: {
      marginBottom: '48px',
    },
  },
  '& .title': {
    [Breakpoints.TabletUp.mq]: {
      marginBottom: '16px',
    },
  },
  '& .absolute-text': {
    [Breakpoints.TabletUp.mq]: {
      bottom: '0',
    },
  },
});

const enum HeroTextTypeEnum {
  ARTICLE = 'article-hero',
  PAGE = 'page-hero',
  DEFAULT = 'default',
  SIGNUP = 'signup-hero',
  SIGNUP_EVENT = 'signup-hero-event',
}

type HeroTextProps = {
  header?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  text?: InternalPropTypes.RichText;
  type?: TypeHeroTypes;
};

class HeroText extends Component<HeroTextProps> {
  static ARTICLE = HeroTypeEnum.ARTICLE;

  static PAGE = HeroTypeEnum.PAGE;

  static SIGNUP = HeroTextTypeEnum.SIGNUP;

  static DEFAULT = HeroTextTypeEnum.DEFAULT;

  static defaultProps = {
    header: null,
    lightHeader: null,
    text: null,
    type: null,
  };

  render() {
    const { header, lightHeader, text, type } = this.props;

    return (
      <div css={styles} className="hero-text">
        <Caption
          type={
            type === HeroText.ARTICLE || type === HeroText.PAGE
              ? Caption.types.HERO
              : Caption.types.SECTION
          }
        >
          {lightHeader}
        </Caption>

        {type === HeroText.PAGE && (
          <Header
            type={
              type === HeroText.PAGE ? Header.types.PAGE : Header.types.ARTICLE
            }
          >
            {header}
          </Header>
        )}

        <div
          className={`text-container ${
            type === HeroText.ARTICLE ? 'absolute-text' : ''
          }`}
        >
          {type === HeroText.ARTICLE && (
            <Header
              type={
                type === HeroText.ARTICLE
                  ? Header.types.ARTICLE
                  : Header.types.ARTICLE
              }
            >
              {header}
            </Header>
          )}

          {type !== HeroText.ARTICLE && type !== HeroText.PAGE && (
            <Title seoWeight="h1" className="title" type={TitleTypes.SECTION}>
              {header}
            </Title>
          )}

          <BodyText
            type={
              type === HeroText.ARTICLE
                ? BodyText.types.ARTICLE
                : BodyText.types.STATIC16
            }
          >
            {text}
          </BodyText>
        </div>
      </div>
    );
  }
}

export default HeroText;
