import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import HeaderTextGroupMolecule from './common/molecules/HeaderTextGroup';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import {
  HeaderTextGroupTypeEnum,
  TypeHeaderTextGroupTypes,
  TypeSEOWeightTypes,
} from '../constants/cms-constants';

const styles = {
  '&.wide, &.large-wide': {
    paddingTop: '56px',
    paddingBottom: '56px',
  },
  '&.narrow, &.large-narrow': {
    paddingTop: '135px',
  },
};

type HeaderTextGroupProps = {
  type?: TypeHeaderTextGroupTypes;
  freezeInBreakpoint?: InternalPropTypes.Breakpoint;
  enableRichText?: boolean;
  headerText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
  backgroundColor?: string;
  seoWeight?: TypeSEOWeightTypes;
};

class HeaderTextGroup extends Component<HeaderTextGroupProps> {
  static types = HeaderTextGroupMolecule.types;

  static defaultProps = {
    type: HeaderTextGroupTypeEnum.WIDE,
    freezeInBreakpoint: Breakpoints.Mobile,
    enableRichText: false,
    headerText: null,
    bodyText: null,
    link: null,
    backgroundColor: null,
    seoWeight: 'h2',
  };

  render() {
    const {
      type,
      freezeInBreakpoint,
      headerText,
      bodyText,
      link,
      backgroundColor,
      enableRichText,
      seoWeight,
    } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div
        className={`header-text-group-component ${type}`}
        css={[styles, addStyle]}
      >
        <ContentOuterBound>
          <ContentSpacer>
            <HeaderTextGroupMolecule
              headerText={headerText}
              bodyText={bodyText}
              link={link}
              type={type}
              freezeInBreakpoint={freezeInBreakpoint}
              enableRichText={enableRichText}
              seoWeight={seoWeight}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default HeaderTextGroup;
