import { Component } from 'react';
import { css } from '@emotion/react';

import Caption from '../atoms/Caption';
import Header from '../atoms/Header';

import * as InternalPropTypes from '../../../constants/internal-types';

const styles = css({
  margin: 'auto',
  '& .caption': {
    marginBottom: '4px',
    textTransform: 'uppercase',
    marginTop: '20px',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
});

type KeyFigureProps = {
  measure?: InternalPropTypes.RichText;
  value?: InternalPropTypes.RichText;
};

class KeyFigure extends Component<KeyFigureProps> {
  static defaultProps = {
    measure: null,
    value: null,
  };

  render() {
    const { measure, value } = this.props;
    return (
      <div css={styles} className="key-figure-element">
        <Header type={Header.types.KEYFIGURE}>{value}</Header>
        <Caption type={Caption.types.SOCIAL}>{measure}</Caption>
      </div>
    );
  }
}

export default KeyFigure;
